<template>
  <v-row>
    <v-col cols="12">
      <embed
        src="https://meeting.iotech.my.id/?app=ACCOUNTING#/projectdocumentintegration"
        width="100%"
        height="800"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "project-document-integration",
};
</script>

<style></style>
